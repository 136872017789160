<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                   <div class="row">
                        <div class="col l-6 m-6 c-12">
                           <md-field :class="{'md-invalid': submitted && $v.entity.sectorCode.$error }">
                               <label for="sectorCode">Mã khu vực</label>
                               <md-input name="sectorCode" v-model="entity.sectorCode"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.sectorCode.required">Vui lòng nhập mã khu vực</span>
                           </md-field>
                        </div>
                        <div class="col l-6 m-6 c-12">
                           <md-field :class="{'md-invalid': submitted && $v.entity.sectorName.$error }">
                               <label for="sectorName">Tên khu vực</label>
                               <md-input name="sectorName" v-model="entity.sectorName"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.sectorName.required">Vui lòng nhập tên khu vực</span>
                           </md-field>
                        </div>
                    </div>
                </div>
                <div class="form-body form-tab">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-tabs>
                                <md-tab id="tab-contract" class="tab-contract" md-label="Địa điểm">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th> 
                                                            <th style="width:25%;">Mã địa điểm</th> 
                                                            <th style="width:30%;">Tên viết tắt</th> 
                                                            <th style="width:30%;">Tên địa điểm</th> 
                                                            <th style="width:10%;">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.locations" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td> 
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.locationCode" class="form-control" type="text">
                                                                    <md-button @click="openLocation(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm địa điểm</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="left">{{item.locationAlias}}</td> 
                                                            <td class="left">{{item.locationName}}</td> 
                                                            <td class="grid-action">
                                                                <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td> 
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                            </md-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <locationList ref="locationList" @close="closeLocation"/>
   </div>
</template>
<script>
    import sectorService from '@/api/sectorService';
    import messageBox from '@/utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '@/mixins';
    import locationList from '@/components/popup/_LocationList.vue';

    export default ({
        components: {
            locationList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật khu vực'
        },
        data() {
            return {
               title: '',
               id: 0,
               loadding: false,
               submitted: false,
               entity: { id: 0, sectorCode: '', sectorName: '', locations: [] },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật khu vực';
               this.getById();
            }
            else{
               this.title = 'Thêm mới khu vực';
               this.entity.locations.push({ id: 'id_' + common.getFakeId(), sectorId: 0, locationCode: '', locationAlias: '', locationName: '' });
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            delRow(item){
                const index = this.entity.locations.findIndex(x => x.id == item.id);
                this.entity.locations.splice(index, 1);
            },

            addRow(){
                let item = { id: 'id_' + common.getFakeId(), sectorId: 0, locationCode: '', locationAlias: '', locationName: '' };
                this.entity.locations.push(item);
            },

            closeLocation(item){
                const selected = this.entity.locations.findIndex(x => x.id == this.selectedId);
                this.entity.locations[selected].id = item.id;
                this.entity.locations[selected].locationCode = item.locationCode;
                this.entity.locations[selected].locationAlias = item.locationAlias;
                this.entity.locations[selected].locationName = item.locationName;
                this.entity.locations[selected].location = item.location;
                this.$refs.locationList.close();
                this.selectedId = '';
            },

            openLocation(id){
                this.selectedId = id;
                this.$refs.locationList.open();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.locations !== undefined && this.entity.locations.length > 0){
                    this.entity.locations = this.entity.locations.filter(item => !(item.locationCode === ''));
                    this.entity.locations.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }

                if (this.entity.locations === undefined || this.entity.locations.length === 0){
                    messageBox.showWarning("Vui lòng thêm địa điểm");
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                sectorService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                sectorService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/sector');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                sectorService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/sector');
            }
        },
        validations: {
            entity: {
                sectorCode: { required },
                sectorName: { required }
            }
       }
     })

</script>
